import React, { Component } from 'react';
import 'openapi-explorer';

import './OpenApiExplorer.css'

export class OpenApiExplorer extends Component 
{
  render() 
  {
    return <table style={{width:'100%'}}>
            <tr style={{}}>
              <td style={{paddingLeft:'10px', paddingRight:'10px'}}>
                <img src="https://res.cloudinary.com/rms-jinoby/image/upload/c_fill,g_custom,q_100,w_160/g_custom/v1/app/kds/30.0.0/Logo-kds.png" />
              </td>
              <td style={{textAlign:'center', paddingTop:'10px', paddingBottom:'5px'}}>
                  <h1 style={{color:'#05149E'}}>
                    Flipdish API Documentation
                  </h1>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <hr style={{margin:'0px', padding:'0px'}}/>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
              <openapi-explorer
                  use-path-in-nav-bar = {true}
                  spec-url = "https://sarmsdevinfrastructure.z35.web.core.windows.net/swagger.json">
                </openapi-explorer>
              </td>
            </tr>
          </table>
    
    
  }
}