import React from 'react';
import { OpenApiExplorer } from './OpenApiExplorer';

function App() {
// return <div className='flex-column align-items-start h-100'>
//   <div className='shadow-sm bg-white p-2'  style={{"border-bottom": "1px solid #05149E" }}>
//     <img src="https://res.cloudinary.com/rms-jinoby/image/upload/c_fill,g_custom,q_100,w_160/g_custom/v1/app/kds/30.0.0/Logo-kds.png" />
//   </div>
//   <div className='flex-grow-1'>
//     <OpenApiExplorer />
//   </div>
// </div>
  return <OpenApiExplorer />;
}

export default App;
